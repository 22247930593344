import { lazy } from "react";

const ServiceGroupList = lazy(() => import("./serviceGroupList"));

const serviceGroupRoutes = [
  {
    path: "/service-group",
    element: <ServiceGroupList />,
  }
];

export default serviceGroupRoutes;
