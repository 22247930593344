
const initialState = {
    supplierList: []
}

const ServiceOrderReducer = function (state = initialState, action) {
    switch (action.type) {
        case "GET_SERVICE_ORDER_SUCCESS": {
            return {
                ...state,
                getServiceOrderSuccess: true,
                getServiceOrderList: action.payload.data,
            };
        }
        case "GET_SERVICE_ORDER_FAILURE": {
            return {
                ...state,
                getServiceOrderFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_SERVICE_ORDER": {
            return {
                ...state,
                getServiceOrderSuccess: false,
                getServiceOrderFailure: false,
                getServiceOrderList: [],
                errorMessage: false
            };
        }
        case "CREATE_ASSIGN_SERVICE_ORDER_SUCCESS": {
            return {
                ...state,
                createAssignServiceOrderSuccess: true,
                createAssignServiceOrderList: action.payload.data,
            };
        }
        case "CREATE_ASSIGN_SERVICE_ORDER_FAILURE": {
            return {
                ...state,
                createAssignServiceOrderFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_ASSIGN_SERVICE_ORDER": {
            return {
                ...state,
                createAssignServiceOrderSuccess: false,
                createAssignServiceOrderFailure: false,
                createAssignServiceOrderList: [],
                errorMessage: false
            };
        }
        case "ASSIGN_SERVICE_ORDER_SUCCESS": {
            return {
                ...state,
                assignServiceOrderSuccess: true,
                assignServiceOrderList: action.payload.data,
            };
        }
        case "ASSIGN_SERVICE_ORDER_FAILURE": {
            return {
                ...state,
                assignServiceOrderFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_ASSIGN_SERVICE_ORDER": {
            return {
                ...state,
                assignServiceOrderSuccess: false,
                assignServiceOrderFailure: false,
                assignServiceOrderList: [],
                errorMessage: false
            };
        }

        case 'UPDATE_SERVICE_ORDER_SUCCESS': {            
            return {
                updateServiceOrderSuccess: true,
                updateServiceOrderData: action.payload.data,
            }
        }
        case 'UPDATE_SERVICE_ORDER_FAILURE': {
            return {
                updateServiceOrderFailed: true,
                errorMessage: action.data.message
            }
        }
        case 'RESET_UPDATE_SERVICE_ORDER': {
            return {
                updateServiceOrderSuccess: false,
                updateServiceOrderFailed: false,
                updateServiceOrderData: false,
                errorMessage: false
            }
        }  
        
        
        case 'UPDATE_SERVICE_ORDER_PAYMENT_SUCCESS': {            
            return {
                updateServiceOrderPaymentSuccess: true,
                updateServiceOrderPaymentData: action.payload.data,
            }
        }
        case 'UPDATE_SERVICE_ORDER_PAYMENT_FAILURE': {
            return {
                updateServiceOrderPaymentFailed: true,
                errorMessage: action.data.message
            }
        }
        case 'RESET_UPDATE_SERVICE_ORDER_PAYMENT': {
            return {
                updateServiceOrderPaymentSuccess: false,
                updateServiceOrderPaymentFailed: false,
                updateServiceOrderPaymentData: false,
                errorMessage: false
            }
        }   
        default: {
            return {
                ...state,
            }
        }
    }
}

export default ServiceOrderReducer

