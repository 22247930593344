const initialState = {
    stateList: []  
  }
  
  const StateReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_STATE_SUCCESS": {             
        return {
          ...state,
          getStateSuccess: true,
          getStateList: action.payload.data,
        };
      }
      case "GET_STATE_FAILURE": {
        return {
          ...state,
          getStateFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_STATE": {
        return {
          ...state,
          getStateSuccess: false,
          getStateFailure: false,
          getStateList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_STATE_SUCCESS": {             
        return {
          ...state,
          createStateSuccess: true,
          createStateData: action.payload.data,
        };
      }
      case "CREATE_STATE_FAILURE": {
        return {
          ...state,
          createStateFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_STATE": {
        return {
          ...state,
          createStateSuccess: false,
          createStateFailure: false,
          createStateData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_STATE_SUCCESS": {             
        return {
          ...state,
          updateStateSuccess: true,
          updateStateData: action.payload.data,
        };
      }
      case "UPDATE_STATE_FAILURE": {
        return {
          ...state,
          updateStateFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_STATE": {
        return {
          ...state,
          updateStateSuccess: false,
          updateStateFailure: false,
          updateStateData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default StateReducer
  
  