import { lazy } from "react";

const ServiceTypeList = lazy(() => import("./serviceTypeList"));

const serviceTypeRoutes = [
  {
    path: "/service-type",
    element: <ServiceTypeList />,
  }
];

export default serviceTypeRoutes;
