const initialState = {
    orderStatusList: []  
  }
  
  const OrderStatusReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_ORDER_STATUS_SUCCESS": {             
        return {
          ...state,
          getOrderStatusSuccess: true,
          getOrderStatusList: action.payload.data,
        };
      }
      case "GET_ORDER_STATUS_FAILURE": {
        return {
          ...state,
          getOrderStatusFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ORDER_STATUS": {
        return {
          ...state,
          getOrderStatusSuccess: false,
          getOrderStatusFailure: false,
          getOrderStatusList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_ORDER_STATUS_SUCCESS": {             
        return {
          ...state,
          createOrderStatusSuccess: true,
          createOrderStatusData: action.payload.data,
        };
      }
      case "CREATE_ORDER_STATUS_FAILURE": {
        return {
          ...state,
          createOrderStatusFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ORDER_STATUS": {
        return {
          ...state,
          createOrderStatusSuccess: false,
          createOrderStatusFailure: false,
          createOrderStatusData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_ORDER_STATUS_SUCCESS": {             
        return {
          ...state,
          updateOrderStatusSuccess: true,
          updateOrderStatusData: action.payload.data,
        };
      }
      case "UPDATE_ORDER_STATUS_FAILURE": {
        return {
          ...state,
          updateOrderStatusFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ORDER_STATUS": {
        return {
          ...state,
          updateOrderStatusSuccess: false,
          updateOrderStatusFailure: false,
          updateOrderStatusData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default OrderStatusReducer
  
  