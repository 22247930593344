const initialState = {
    cityList: []  
  }
  
  const CityReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_CITY_SUCCESS": {             
        return {
          ...state,
          getCitySuccess: true,
          getCityList: action.payload.data,
        };
      }
      case "GET_CITY_FAILURE": {
        return {
          ...state,
          getCityFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_CITY": {
        return {
          ...state,
          getCitySuccess: false,
          getCityFailure: false,
          getCityList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_CITY_SUCCESS": {             
        return {
          ...state,
          createCitySuccess: true,
          createCityData: action.payload.data,
        };
      }
      case "CREATE_CITY_FAILURE": {
        return {
          ...state,
          createCityFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_CITY": {
        return {
          ...state,
          createCitySuccess: false,
          createCityFailure: false,
          createCityData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_CITY_SUCCESS": {             
        return {
          ...state,
          updateCitySuccess: true,
          updateCityData: action.payload.data,
        };
      }
      case "UPDATE_CITY_FAILURE": {
        return {
          ...state,
          updateCityFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_CITY": {
        return {
          ...state,
          updateCitySuccess: false,
          updateCityFailure: false,
          updateCityData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default CityReducer
  
  