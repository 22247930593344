
const initialState = {
    userList: []
}

const EmployeeReducer = function (state = initialState, action) {
    switch (action.type) {
        case "GET_EMPLOYEE_SUCCESS": {
            return {
                ...state,
                getEmployeeSuccess: true,
                getEmployeeList: action.payload.data,
            };
        }
        case "GET_EMPLOYEE_FAILURE": {
            return {
                ...state,
                getEmployeeFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_EMPLOYEE": {
            return {
                ...state,
                getEmployeeSuccess: false,
                getEmployeeFailure: false,
                getEmployeeList: [],
                errorMessage: false
            };
        }

        case "GET_EMPLOYEE_DETAILS_SUCCESS": {
            return {
                ...state,
                getEmployeeDetailsSuccess: true,
                getEmployeeDetailsList: action.payload.data,
            };
        }
        case "GET_EMPLOYEE_DETAILS_FAILURE": {
            return {
                ...state,
                getEmployeeDetailsFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_EMPLOYEE_DETAILS": {
            return {
                ...state,
                getEmployeeDetailsSuccess: false,
                getEmployeeDetailsFailure: false,
                getEmployeeDetailsList: [],
                errorMessage: false
            };
        }
        case "CREATE_EMPLOYEE_SUCCESS": {
            return {
                ...state,
                createEmployeeSuccess: true,
                createEmployeeData: action.payload.data,
            };
        }
        case "CREATE_EMPLOYEE_FAILURE": {
            return {
                ...state,
                createEmployeeFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_EMPLOYEE": {
            return {
                ...state,
                createEmployeeSuccess: false,
                createEmployeeFailure: false,
                createEmployeeData: [],
                errorMessage: false
            };
        }
        case "UPDATE_EMPLOYEE_SUCCESS": {
            return {
                ...state,
                updateEmployeeSuccess: true,
                updateEmployeeData: action.payload.data,
            };
        }
        case "UPDATE_EMPLOYEE_FAILURE": {
            return {
                ...state,
                updateEmployeeFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_UPDATE_EMPLOYEE": {
            return {
                ...state,
                updateEmployeeSuccess: false,
                updateEmployeeFailure: false,
                updateEmployeeData: [],
                errorMessage: false
            };
        }

        case "GET_EMPLOYEE_TYPE_SUCCESS": {
            return {
                ...state,
                getEmployeeTypeSuccess: true,
                getEmployeeTypeList: action.payload.data,
            };
        }
        case "GET_EMPLOYEE_TYPE_FAILURE": {
            return {
                ...state,
                getEmployeeTypeFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_EMPLOYEE_TYPE": {
            return {
                ...state,
                getEmployeeTypeSuccess: false,
                getEmployeeTypeFailure: false,
                getEmployeeTypeList: [],
                errorMessage: false
            };
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default EmployeeReducer

