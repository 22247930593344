import { lazy } from "react";

const CategoryList = lazy(() => import("./categoryList"));

const categoryRoutes = [
  {
    path: "/category",
    element: <CategoryList />,
  }
];

export default categoryRoutes;
