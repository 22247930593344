import { lazy } from "react";

const ReportServiceOrderList = lazy(() => import("./reportServiceOrderList"));

const reportServiceOrderRoutes = [
  {
    path: "/report-service-order",
    element: <ReportServiceOrderList />,
  }
];

export default reportServiceOrderRoutes;