const initialState = {
    serviceDetailsList: []  
  }
  
  const ServiceDetailsReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SERVICE_DETAILS_SUCCESS": {             
        return {
          ...state,
          getServiceDetailsSuccess: true,
          getServiceDetailsList: action.payload.data,
        };
      }
      case "GET_SERVICE_DETAILS_FAILURE": {
        return {
          ...state,
          getServiceDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE_DETAILS": {
        return {
          ...state,
          getServiceDetailsSuccess: false,
          getServiceDetailsFailure: false,
          getServiceDetailsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SERVICE_DETAILS_SUCCESS": {             
        return {
          ...state,
          createServiceDetailsSuccess: true,
          createServiceDetailsData: action.payload.data,
        };
      }
      case "CREATE_SERVICE_DETAILS_FAILURE": {
        return {
          ...state,
          createServiceDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SERVICE_DETAILS": {
        return {
          ...state,
          createServiceDetailsSuccess: false,
          createServiceDetailsFailure: false,
          createServiceDetailsData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SERVICE_DETAILS_SUCCESS": {             
        return {
          ...state,
          updateServiceDetailsSuccess: true,
          updateServiceDetailsData: action.payload.data,
        };
      }
      case "UPDATE_SERVICE_DETAILS_FAILURE": {
        return {
          ...state,
          updateServiceDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SERVICE_DETAILS": {
        return {
          ...state,
          updateServiceDetailsSuccess: false,
          updateServiceDetailsFailure: false,
          updateServiceDetailsData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ServiceDetailsReducer
  
  