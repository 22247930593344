  const initialState = {
    serviceTypeList: []  
  }
  
  const ServiceTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SERVICE_TYPE_SUCCESS": {             
        return {
          ...state,
          getServiceTypeSuccess: true,
          getServiceTypeList: action.payload.data,
        };
      }
      case "GET_SERVICE_TYPE_FAILURE": {
        return {
          ...state,
          getServiceTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE_TYPE": {
        return {
          ...state,
          getServiceTypeSuccess: false,
          getServiceTypeFailure: false,
          getServiceTypeList: [],
          errorMessage: false
        };
      }      
      case "CREATE_SERVICE_TYPE_SUCCESS": {             
        return {
          ...state,
          createServiceTypeSuccess: true,
          createServiceTypeData: action.payload.data,
        };
      }
      case "CREATE_SERVICE_TYPE_FAILURE": {
        return {
          ...state,
          createServiceTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SERVICE_TYPE": {
        return {
          ...state,
          createServiceTypeSuccess: false,
          createServiceTypeFailure: false,
          createServiceTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SERVICE_TYPE_SUCCESS": {             
        return {
          ...state,
          updateServiceTypeSuccess: true,
          updateServiceTypeData: action.payload.data,
        };
      }
      case "UPDATE_SERVICE_TYPE_FAILURE": {
        return {
          ...state,
          updateServiceTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SERVICE_TYPE": {
        return {
          ...state,
          updateServiceTypeSuccess: false,
          updateServiceTypeFailure: false,
          updateServiceTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ServiceTypeReducer
  
  