const initialState = {
    subServiceList: []  
  }
  
  const SubServiceReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SUB_SERVICE_SUCCESS": {     
        return {
          ...state,
          getSubServiceSuccess: true,
          getSubServiceList: action.payload.data,
        };
      }
      case "GET_SUB_SERVICE_FAILURE": {
        return {
          ...state,
          getSubServiceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SUB_SERVICE": {
        return {
          ...state,
          getSubServiceSuccess: false,
          getSubServiceFailure: false,
          getSubServiceList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SUB_SERVICE_SUCCESS": {             
        return {
          ...state,
          createSubServiceSuccess: true,
          createSubServiceData: action.payload.data,
        };
      }
      case "CREATE_SUB_SERVICE_FAILURE": {
        return {
          ...state,
          createSubServiceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SUB_SERVICE": {
        return {
          ...state,
          createSubServiceSuccess: false,
          createSubServiceFailure: false,
          createSubServiceData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SUB_SERVICE_SUCCESS": {             
        return {
          ...state,
          updateSubServiceSuccess: true,
          updateSubServiceData: action.payload.data,
        };
      }
      case "UPDATE_SUB_SERVICE_FAILURE": {
        return {
          ...state,
          updateSubServiceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SUB_SERVICE": {
        return {
          ...state,
          updateSubServiceSuccess: false,
          updateSubServiceFailure: false,
          updateSubServiceData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SubServiceReducer
  
  