import { lazy } from "react";

const CityList = lazy(() => import("./cityList"));

const cityRoutes = [
  {
    path: "/city",
    element: <CityList />,
  }
];

export default cityRoutes;
