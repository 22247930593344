
const initialState = {
  purchaseOrderList: []  
}

const OrderReducer = function (state = initialState, action) {  
  switch (action.type) {
    case "GET_UNASSIGNED_ORDER_SUCCESS": {             
      return {
        ...state,
        getUnassignedOrderSuccess: true,
        getUnassignedOrderList: action.payload.data,
      };
    }
    case "GET_UNASSIGNED_ORDER_FAILURE": {
      return {
        ...state,
        getUnassignedOrderFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_UNASSIGNED_ORDER": {
      return {
        ...state,
        getUnassignedOrderSuccess: false,
        getUnassignedOrderFailure: false,
        getUnassignedOrderList: [],
        errorMessage: false
      };
    }

    case "GET_ORDER_SUCCESS": {
      return {
        ...state,
        getOrderSuccess: true,
        getOrderList: action.payload.data,
      };
    }
    case "GET_ORDER_FAILURE": {
      return {
        ...state,
        getOrderFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_ORDER": {
      return {
        ...state,
        getOrderSuccess: false,
        getOrderFailure: false,
        getOrderList: [],
        errorMessage: false
      };
    }
    
    case "GET_CONVERT_ORDER_SUCCESS": {             
      return {
        ...state,
        getConvertOrderSuccess: true,
        getConvertOrderList: action.payload.data,
      };
    }
    case "GET_CONVERT_ORDER_FAILURE": {
      return {
        ...state,
        getConvertOrderFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_CONVERT_ORDER": {
      return {
        ...state,
        getConvertOrderSuccess: false,
        getConvertOrderFailure: false,
        getConvertOrderList: [],
        errorMessage: false
      };
    }


    case "GET_CUSTOMER_ORDER_SUCCESS": {             
      return {
        ...state,
        getCustomerOrderSuccess: true,
        getCustomerOrderList: action.payload.data,
      };
    }
    case "GET_CUSTOMER_ORDER_FAILURE": {
      return {
        ...state,
        getCustomerOrderFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_CUSTOMER_ORDER": {
      return {
        ...state,
        getCustomerOrderSuccess: false,
        getCustomerOrderFailure: false,
        getCustomerOrderList: [],
        errorMessage: false
      };
    }

    case "CREATE_ORDER_SUCCESS": {             
      return {
        ...state,
        createOrderSuccess: true,
        createOrderData: action.payload.data,
      };
    }
    case "CREATE_ORDER_FAILURE": {
      return {
        ...state,
        createOrderFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_CREATE_ORDER": {
      return {
        ...state,
        createOrderSuccess: false,
        createOrderFailure: false,
        errorMessage: false         
      };
    }

    case "UPDATE_ORDER_SUCCESS": {             
      return {
        ...state,
        updateOrderSuccess: true,
        updateOrderData: action.payload.data,
      };
    }
    case "UPDATE_ORDER_FAILURE": {
      return {
        ...state,
        updateOrderFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_UPDATE_ORDER": {
      return {
        ...state,
        updateOrderSuccess: false,
        updateOrderFailure: false,
        updateOrderData: false,
        errorMessage: false         
      };
    }

    case "UPDATE_ORDER_STATUS_SUCCESS": {          
      return {
        ...state,
        updateOrderStatusSuccess: true,
      };
    }
    case "UPDATE_ORDER_STATUS_FAILURE": {
      return {
        ...state,
        updateOrderStatusFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_UPDATE_ORDER_STATUS": {
      return {
        ...state,
        updateOrderStatusSuccess: false,
        updateOrderStatusFailure: false,
        errorMessage: false         
      };
    }

    case "RELEASE_RESERVATION_SUCCESS": {          
      return {
        ...state,
        releaseReservationSuccess: true,
      };
    }
    case "RELEASE_RESERVATION_FAILURE": {
      return {
        ...state,
        releaseReservationFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_RELEASE_RESERVATION": {
      return {
        ...state,
        releaseReservationSuccess: false,
        releaseReservationFailure: false,
        errorMessage: false         
      };
    }


    // Customer Receipt

    case "GET_CUSTOMER_RECEIPT_SUCCESS": {             
      return {
        ...state,
        getCustomerReceiptSuccess: true,
        getCustomerReceiptList: action.payload.data,
      };
    }
    case "GET_CUSTOMER_RECEIPT_FAILURE": {
      return {
        ...state,
        getCustomerReceiptFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_CUSTOMER_RECEIPT": {
      return {
        ...state,
        getCustomerReceiptSuccess: false,
        getCustomerReceiptFailure: false,
        getCustomerReceiptList: [],
        errorMessage: false
      };
    }


    case "CREATE_CUSTOMER_RECEIPT_SUCCESS": {             
      return {
        ...state,
        createCustomerReceiptSuccess: action.payload.data,
      };
    }
    case "CREATE_CUSTOMER_RECEIPT_FAILURE": {
      return {
        ...state,
        createCustomerReceiptFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_CREATE_CUSTOMER_RECEIPT": {
      return {
        ...state,
        createCustomerReceiptSuccess: false,
        createCustomerReceiptFailure: false,
        errorMessage: false         
      };
    }



    case "UPDATE_CUSTOMER_RECEIPT_SUCCESS": {             
      return {
        ...state,
        updateCustomerReceiptSuccess: action.payload.data,
      };
    }
    case "UPDATE_CUSTOMER_RECEIPT_FAILURE": {
      return {
        ...state,
        updateCustomerReceiptFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_UPDATE_CUSTOMER_RECEIPT": {
      return {
        ...state,
        updateCustomerReceiptSuccess: false,
        updateCustomerReceiptFailure: false,
        errorMessage: false         
      };
    }

    // Credit Notes 

    case "GET_CREDIT_NOTES_SUCCESS": {             
      return {
        ...state,
        getCredtiNotesSuccess: true,
        getCredtiNotesList: action.payload.data,
      };
    }
    case "GET_CREDIT_NOTES_FAILURE": {
      return {
        ...state,
        getCredtiNotesFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_CREDIT_NOTES": {
      return {
        ...state,
        getCredtiNotesSuccess: false,
        getCredtiNotesFailure: false,
        getCredtiNotesList: [],
        errorMessage: false
      };
    }


    case "CREATE_CREDIT_NOTES_SUCCESS": {             
      return {
        ...state,
        createCreditNotesSuccess: action.payload.data,
      };
    }
    case "CREATE_CREDIT_NOTES_FAILURE": {
      return {
        ...state,
        createCreditNotesFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_CREATE_CREDIT_NOTES": {
      return {
        ...state,
        createCreditNotesSuccess: false,
        createCreditNotesFailure: false,
        errorMessage: false         
      };
    }

    case "UPDATE_CREDIT_NOTES_SUCCESS": {             
      return {
        ...state,
        updateCreditNotesSuccess: action.payload.data,
      };
    }
    case "UPDATE_CREDIT_NOTES_FAILURE": {
      return {
        ...state,
        updateCreditNotesFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_UPDATE_CREDIT_NOTES": {
      return {
        ...state,
        updateCreditNotesSuccess: false,
        updateCreditNotesFailure: false,
        errorMessage: false         
      };
    }

    case "GET_ORDER_HISTORY_SUCCESS": {             
      return {
        ...state,
        getOrderHistorySuccess: true,
        getOrderHistoryList: action.payload.data,
      };
    }
    case "GET_ORDER_HISTORY_FAILURE": {
      return {
        ...state,
        getOrderHistoryFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_ORDER_HISTORY": {
      return {
        ...state,
        getOrderHistorySuccess: false,
        getOrderHistoryList: false,
        getOrderHistoryFailure: false,
        errorMessage: false         
      };
    }

    default: {
      return {
        ...state,
      }
    }
  }
}

export default OrderReducer

