
const initialState = {
    serviceStatusList: []  
    }
    
    const ServiceStatusReducer = function (state = initialState, action) {  
      switch (action.type) {
        case "GET_SERVICE_STATUS_SUCCESS": {               
          return {
            ...state,
            getServiceStatusSuccess: true,
            getServiceStatusList: action.payload.data,
          };
        }
        case "GET_SERVICE_STATUS_FAILURE": {
          return {
            ...state,
            getServiceStatusFailure: true,
            errorMessage: action.errorMessage
          };
        }
        case "RESET_GET_SERVICE_STATUS": {
          return {
            ...state,
            getServiceStatusSuccess: false,
            getServiceStatusFailure: false,
            getServiceStatusList: [],
            errorMessage: false
          };
        }
        case "CREATE_SERVICE_STATUS_SUCCESS": {             
          return {
            ...state,
            createServiceStatusSuccess: true,
            createServiceStatusData: action.payload.data,
          };
        }
        case "CREATE_SERVICE_STATUS_FAILURE": {
          return {
            ...state,
            createServiceStatusFailure: true,
            errorMessage: action.errorMessage
          };
        }
        case "RESET_CREATE_SERVICE_STATUS": {
          return {
            ...state,
            createServiceStatusSuccess: false,
            createServiceStatusFailure: false,
            createServiceStatusData: [],
            errorMessage: false
          };
        }
        case "UPDATE_SERVICE_STATUS_SUCCESS": {           
          return {
            ...state,
            updateServiceStatusSuccess: true,
            updateServiceStatusData: action.payload.data,
          };
        }
        case "UPDATE_SERVICE_STATUS_FAILURE": {
          return {
            ...state,
            updateServiceStatusFailure: true,
            errorMessage: action.errorMessage
          };
        }
        case "RESET_UPDATE_SERVICE_STATUS": {
          return {
            ...state,
            updateServiceStatusSuccess: false,
            updateServiceStatusFailure: false,
            updateServiceStatusData: [],
            errorMessage: false
          };
        } 
        default: {
          return {
            ...state,
          }
        }
      }
    }
    
    export default ServiceStatusReducer
    
    