import { lazy } from "react";

const ZoneList = lazy(() => import("./zoneList"));

const ZoneRoutes = [
  {
    path: "/zone",
    element: <ZoneList />,
  }
];

export default ZoneRoutes;
