const initialState = {
    timeSoltList: []  
  }
  
  const TimeSoltReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_TIME_SOLT_SUCCESS": {             
        return {
          ...state,
          getTimeSoltSuccess: true,
          getTimeSoltList: action.payload.data,
        };
      }
      case "GET_TIME_SOLT_FAILURE": {
        return {
          ...state,
          getTimeSoltFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_TIME_SOLT": {
        return {
          ...state,
          getTimeSoltSuccess: false,
          getTimeSoltFailure: false,
          getTimeSoltList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_TIME_SOLT_SUCCESS": {             
        return {
          ...state,
          createTimeSoltSuccess: true,
          createTimeSoltData: action.payload.data,
        };
      }
      case "CREATE_TIME_SOLT_FAILURE": {
        return {
          ...state,
          createTimeSoltFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_TIME_SOLT": {
        return {
          ...state,
          createTimeSoltSuccess: false,
          createTimeSoltFailure: false,
          createTimeSoltData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_TIME_SOLT_SUCCESS": {             
        return {
          ...state,
          updateTimeSoltSuccess: true,
          updateTimeSoltData: action.payload.data,
        };
      }
      case "UPDATE_TIME_SOLT_FAILURE": {
        return {
          ...state,
          updateTimeSoltFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_TIME_SOLT": {
        return {
          ...state,
          updateTimeSoltSuccess: false,
          updateTimeSoltFailure: false,
          updateTimeSoltData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default TimeSoltReducer
  
  