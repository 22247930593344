import { lazy } from "react";

const AccountSettingsList = lazy(() => import("./accountSettingsList"));

const accountSettingsRoutes = [
  {
    path: "/account-settings",
    element: <AccountSettingsList />,
  }
];

export default accountSettingsRoutes;
