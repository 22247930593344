const initialState = {
    reportServiceOrderList: []  
  }
  
  const ReportServiceOrderReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_REPORT_SERVICE_ORDER_SUCCESS": {             
        return {
          ...state,
          getReportServiceOrderSuccess: true,
          getReportServiceOrderList: action.payload.data,
        };
      }
      case "GET_REPORT_SERVICE_ORDER_FAILURE": {
        return {
          ...state,
          getReportServiceOrderFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_REPORT_SERVICE_ORDER": {
        return {
          ...state,
          getReportServiceOrderSuccess: false,
          getReportServiceOrderFailure: false,
          getReportServiceOrderList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_REPORT_SERVICE_ORDER_SUCCESS": {             
        return {
          ...state,
          createReportServiceOrderSuccess: true,
          createReportServiceOrderData: action.payload.data,
        };
      }
      case "CREATE_REPORT_SERVICE_ORDER_FAILURE": {
        return {
          ...state,
          createReportServiceOrderFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_REPORT_SERVICE_ORDER": {
        return {
          ...state,
          createReportServiceOrderSuccess: false,
          createReportServiceOrderFailure: false,
          createReportServiceOrderData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_REPORT_SERVICE_ORDER_SUCCESS": {             
        return {
          ...state,
          updateReportServiceOrderSuccess: true,
          updateReportServiceOrderData: action.payload.data,
        };
      }
      case "UPDATE_REPORT_SERVICE_ORDER_FAILURE": {
        return {
          ...state,
          updateReportServiceOrderFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_REPORT_SERVICE_ORDER": {
        return {
          ...state,
          updateReportServiceOrderSuccess: false,
          updateReportServiceOrderFailure: false,
          updateReportServiceOrderData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ReportServiceOrderReducer
  
  