
const initialState = {
    serviceGroupList: []  
  }
  
  const ServiceGroupReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SERVICE_GROUP_SUCCESS": {               
        return {
          ...state,
          getServiceGroupSuccess: true,
          getServiceGroupList: action.payload.data,
        };
      }
      case "GET_SERVICE_GROUP_FAILURE": {
        return {
          ...state,
          getServiceGroupFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE_GROUP": {
        return {
          ...state,
          getServiceGroupSuccess: false,
          getServiceGroupFailure: false,
          getServiceGroupList: [],
          errorMessage: false
        };
      }

      case "GET_SERVICE_GROUP_DETAILS_SUCCESS": {               
        return {
          ...state,
          getServiceGroupDetailsSuccess: true,
          getServiceGroupDetailsList: action.payload.data,
        };
      }
      case "GET_SERVICE_GROUP_DETAILS_FAILURE": {
        return {
          ...state,
          getServiceGroupDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE_GROUP_DETAILS": {
        return {
          ...state,
          getServiceGroupDetailsSuccess: false,
          getServiceGroupDetailsFailure: false,
          getServiceGroupDetailsList: [],
          errorMessage: false
        };
      }
      case "CREATE_SERVICE_GROUP_SUCCESS": {             
        return {
          ...state,
          createServiceGroupSuccess: true,
          createServiceGroupData: action.payload.data,
        };
      }
      case "CREATE_SERVICE_GROUP_FAILURE": {
        return {
          ...state,
          createServiceGroupFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SERVICE_GROUP": {
        return {
          ...state,
          createServiceGroupSuccess: false,
          createServiceGroupFailure: false,
          createServiceGroupData: [],
          errorMessage: false
        };
      }
      case "UPDATE_SERVICE_GROUP_SUCCESS": {             
        return {
          ...state,
          updateServiceGroupSuccess: true,
          updateServiceGroupData: action.payload.data,
        };
      }
      case "UPDATE_SERVICE_GROUP_FAILURE": {
        return {
          ...state,
          updateServiceGroupFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SERVICE_GROUP": {
        return {
          ...state,
          updateServiceGroupSuccess: false,
          updateServiceGroupFailure: false,
          updateServiceGroupData: [],
          errorMessage: false
        };
      } 
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ServiceGroupReducer
  
  