const initialState = {
    userTypeList: []  
  }
  
  const UserTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_USER_TYPE_SUCCESS": {             
        return {
          ...state,
          getUserTypeSuccess: true,
          getUserTypeList: action.payload.data,
        };
      }
      case "GET_USER_TYPE_FAILURE": {
        return {
          ...state,
          getUserTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_USER_TYPE": {
        return {
          ...state,
          getUserTypeSuccess: false,
          getUserTypeFailure: false,
          getUserTypeList: [],
          errorMessage: false
        };
      }      
      case "CREATE_USER_TYPE_SUCCESS": {             
        return {
          ...state,
          createUserTypeSuccess: true,
          createUserTypeData: action.payload.data,
        };
      }
      case "CREATE_USER_TYPE_FAILURE": {
        return {
          ...state,
          createUserTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_USER_TYPE": {
        return {
          ...state,
          createUserTypeSuccess: false,
          createUserTypeFailure: false,
          createUserTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_USER_TYPE_SUCCESS": {             
        return {
          ...state,
          updateUserTypeSuccess: true,
          updateUserTypeData: action.payload.data,
        };
      }
      case "UPDATE_USER_TYPE_FAILURE": {
        return {
          ...state,
          updateUserTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_USER_TYPE": {
        return {
          ...state,
          updateUserTypeSuccess: false,
          updateUserTypeFailure: false,
          updateUserTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default UserTypeReducer
  
  