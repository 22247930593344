const initialState = {
    sosServicesList: []  
  }
  
  const SosServicesReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SOS_SERVICE_SUCCESS": {             
        return {
          ...state,
          getSosServicesSuccess: true,
          getSosServicesList: action.payload.data,
        };
      }
      case "GET_SOS_SERVICE_FAILURE": {
        return {
          ...state,
          getSosServicesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SOS_SERVICE": {
        return {
          ...state,
          getSosServicesSuccess: false,
          getSosServicesFailure: false,
          getSosServicesList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SOS_SERVICE_SUCCESS": {             
        return {
          ...state,
          createSosServicesSuccess: true,
          createSosServicesData: action.payload.data,
        };
      }
      case "CREATE_SOS_SERVICE_FAILURE": {
        return {
          ...state,
          createSosServicesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SOS_SERVICE": {
        return {
          ...state,
          createSosServicesSuccess: false,
          createSosServicesFailure: false,
          createSosServicesData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SOS_SERVICE_SUCCESS": {             
        return {
          ...state,
          updateSosServicesSuccess: true,
          updateSosServicesData: action.payload.data,
        };
      }
      case "UPDATE_SOS_SERVICE_FAILURE": {
        return {
          ...state,
          updateSosServicesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SOS_SERVICE": {
        return {
          ...state,
          updateSosServicesSuccess: false,
          updateSosServicesFailure: false,
          updateSosServicesData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SosServicesReducer
  
  