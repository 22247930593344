
import moment from "moment";
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { NotificationContainer, NotificationManager, } from "react-notifications";
const showMessage = (type, msg, title = null) => {
  switch (type) {
    case 'info':
      NotificationManager.info(msg, title);
      break;
    case 'success':
      NotificationManager.success(msg, title);
      break;
    case 'warning':
      NotificationManager.warning(msg, title);
      break;
    case 'error':
      NotificationManager.error(msg, title);
      break;
  }
}
const todayDate = (format = "DD-MM-YYYY") => {
  return moment().format(format);

}
const dateConversion = (date, format = "DD-MM-YYYY") => {
  return date ? moment(date).format(format) : "";

}
const timeConversion = (time, format = "HH:mm:ss") => {
  return time ? moment(time, "HH:mm:ss").format(format) : "";

}
const export2pdf = (headers, data, title, name) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait";
  const marginLeft = 20;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  let content = {
    startY: 50,
    head: headers,
    body: data
  };

  doc.text(title, marginLeft, 20);
  doc.autoTable(content);
  doc.save(`${name}.pdf`)
}
export {
  showMessage,
  todayDate,
  dateConversion,
  timeConversion,
  export2pdf
};