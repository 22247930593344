import { lazy } from "react";

const ServiceList = lazy(() => import("./serviceList"));

const serviceRoutes = [
  {
    path: "/service",
    element: <ServiceList />,
  }
];

export default serviceRoutes;
