import { lazy } from "react";

const ReportOrderList = lazy(() => import("./reportOrderList"));

const reportOrderRoutes = [
  {
    path: "/report-order",
    element: <ReportOrderList />,
  }
];

export default reportOrderRoutes;
