export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200

export const currency = '₹'
//export const baseURL = 'http://192.168.1.92:'
export const baseURL = 'http://192.168.1.73:'
export const ports = {
    "home":"5952",
    "access":"5953",    
    "document":"5952",
    "image":"5954",
    "payroll":"5951",
    "report":"5959"
};
export const imagePath = baseURL+ports.image+'/images/pic/'
export const draftStatus = ['purchaseorder', 'goodsreceived', 'assembleorder', 'assembleorderreceipt', 'quotation', 'sales', 'delivery', 'invoice']
export const productKey = 'BT'
export const serviceStatusCode = {
    "backlog": 1,
    "toDo": 2,
    "inProgress": 3,
    "pendingPayment": 4,
    "complete": 5,
    "cancel": 6,
    "pickUp": 7,
    "delivery": 8,
    "close": 9,
    "review": 10,
    "verified": 11
}