
const initialState = {
  categoryList: []  
  }
  
  const CategoryReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_CATEGORY_SUCCESS": {               
        return {
          ...state,
          getCategorySuccess: true,
          getCategoryList: action.payload.data,
        };
      }
      case "GET_CATEGORY_FAILURE": {
        return {
          ...state,
          getCategoryFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_CATEGORY": {
        return {
          ...state,
          getCategorySuccess: false,
          getCategoryFailure: false,
          getCategoryList: [],
          errorMessage: false
        };
      }
      case "CREATE_CATEGORY_SUCCESS": {             
        return {
          ...state,
          createCategorySuccess: true,
          createCategoryData: action.payload.data,
        };
      }
      case "CREATE_CATEGORY_FAILURE": {
        return {
          ...state,
          createCategoryFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_CATEGORY": {
        return {
          ...state,
          createCategorySuccess: false,
          createCategoryFailure: false,
          createCategoryData: [],
          errorMessage: false
        };
      }
      case "UPDATE_CATEGORY_SUCCESS": {           
        return {
          ...state,
          updateCategorySuccess: true,
          updateCategoryData: action.payload.data,
        };
      }
      case "UPDATE_CATEGORY_FAILURE": {
        return {
          ...state,
          updateCategoryFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_CATEGORY": {
        return {
          ...state,
          updateCategorySuccess: false,
          updateCategoryFailure: false,
          updateCategoryData: [],
          errorMessage: false
        };
      } 
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default CategoryReducer
  
  