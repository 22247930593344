
const initialState = {
    assignCityList: []  
    }
    
    const AssignCityReducer = function (state = initialState, action) {  
      switch (action.type) {
        case "GET_ASSIGN_CITY_SUCCESS": {               
          return {
            ...state,
            getAssignCitySuccess: true,
            getAssignCityList: action.payload.data,
          };
        }
        case "GET_ASSIGN_CITY_FAILURE": {
          return {
            ...state,
            getAssignCityFailure: true,
            errorMessage: action.errorMessage
          };
        }
        case "RESET_GET_ASSIGN_CITY": {
          return {
            ...state,
            getAssignCitySuccess: false,
            getAssignCityFailure: false,
            getAssignCityList: [],
            errorMessage: false
          };
        }
        case "CREATE_ASSIGN_CITY_SUCCESS": {             
          return {
            ...state,
            createAssignCitySuccess: true,
            createAssignCityData: action.payload.data,
          };
        }
        case "CREATE_ASSIGN_CITY_FAILURE": {
          return {
            ...state,
            createAssignCityFailure: true,
            errorMessage: action.errorMessage
          };
        }
        case "RESET_CREATE_ASSIGN_CITY": {
          return {
            ...state,
            createAssignCitySuccess: false,
            createAssignCityFailure: false,
            createAssignCityData: [],
            errorMessage: false
          };
        }
        case "UPDATE_ASSIGN_CITY_SUCCESS": {           
          return {
            ...state,
            updateAssignCitySuccess: true,
            updateAssignCityData: action.payload.data,
          };
        }
        case "UPDATE_ASSIGN_CITY_FAILURE": {
          return {
            ...state,
            updateAssignCityFailure: true,
            errorMessage: action.errorMessage
          };
        }
        case "RESET_UPDATE_ASSIGN_CITY": {
          return {
            ...state,
            updateAssignCitySuccess: false,
            updateAssignCityFailure: false,
            updateAssignCityData: [],
            errorMessage: false
          };
        } 
        default: {
          return {
            ...state,
          }
        }
      }
    }
    
    export default AssignCityReducer
    
    