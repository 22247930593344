import { lazy } from "react";

const ServiceInfoList = lazy(() => import("./serviceInfoList"));

const serviceInfoRoutes = [
  {
    path: "/service-info",
    element: <ServiceInfoList />,
  }
];

export default serviceInfoRoutes;
