const initialState = {
    serviceAssignList: []  
  }
  
  const ServiceAssignReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SERVICE_ASSIGN_SUCCESS": {             
        return {
          ...state,
          getServiceAssignSuccess: true,
          getServiceAssignList: action.payload.data,
        };
      }
      case "GET_SERVICE_ASSIGN_FAILURE": {
        return {
          ...state,
          getServiceAssignFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE_ASSIGN": {
        return {
          ...state,
          getServiceAssignSuccess: false,
          getServiceAssignFailure: false,
          getServiceAssignList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SERVICE_ASSIGN_SUCCESS": {             
        return {
          ...state,
          createServiceAssignSuccess: true,
          createServiceAssignData: action.payload.data,
        };
      }
      case "CREATE_SERVICE_ASSIGN_FAILURE": {
        return {
          ...state,
          createServiceAssignFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SERVICE_ASSIGN": {
        return {
          ...state,
          createServiceAssignSuccess: false,
          createServiceAssignFailure: false,
          createServiceAssignData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SERVICE_ASSIGN_SUCCESS": {             
        return {
          ...state,
          updateServiceAssignSuccess: true,
          updateServiceAssignData: action.payload.data,
        };
      }
      case "UPDATE_SERVICE_ASSIGN_FAILURE": {
        return {
          ...state,
          updateServiceAssignFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SERVICE_ASSIGN": {
        return {
          ...state,
          updateServiceAssignSuccess: false,
          updateServiceAssignFailure: false,
          updateServiceAssignData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ServiceAssignReducer
  
  