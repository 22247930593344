import AuthGuard from "./auth/AuthGuard";
import { createBrowserRouter, redirect } from "react-router-dom";

import categoryRoutes from "./views/category/categoryRoutes";
import serviceOrderRoutes from "./views/service-order/serviceOrderRoutes";
import serviceProviderRoutes from "./views/service-provider/service-provider-route";
import serviceTypeRoutes from "./views/service-type/service-type-route";
import serviceRoutes from "./views/service/serviceRoutes";
import serviceInfoRoutes from "./views/service-info/service-info-route";
import serviceIncludeRoutes from "./views/service-include/service-include-route";
import ZoneRoutes from "./views/zone/zoneRoute";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import chartsRoute from "./views/charts/chartsRoute";

import sessionsRoutes from "./views/sessions/sessionsRoutes";
import dataTableRoute from "./views/dataTable/dataTableRoute";
import invoiceRoutes from "./views/app/invoice/invoiceRoutes";
import inboxRoutes from "./views/app/inbox/inboxRoutes";
import chatRoutes from "./views/app/chat/chatRoutes";
import calendarRoutes from "./views/app/calendar/calendarRoutes";
import taskManagerRoutes from "./views/app/task-manager/taskManagerRoutes";
import contactRoutes from "./views/app/contact/contactRoutes";
import Error404 from "./views/sessions/Error";
import userRoutes from "./views/user/user-route";
import reportOrderRoutes from "./views/report-order/reportOrderRoutes";
import reportServiceOrderRoutes from "./views/report-service-order/categoryRoutes";
import cityRoutes from "./views/city/cityRoutes";



const routes = createBrowserRouter([
  {
    element: <AuthGuard />,
    children: [
      ...serviceOrderRoutes,
      ...serviceProviderRoutes,
      ...serviceTypeRoutes,
      ...categoryRoutes,
      ...serviceRoutes,
      ...userRoutes,
      ...serviceInfoRoutes,
      ...serviceIncludeRoutes,
      ...ZoneRoutes,
      ...dashboardRoutes,
      ...chartsRoute,
      ...reportOrderRoutes,
      ...reportServiceOrderRoutes,
      ...cityRoutes,




      ...dataTableRoute,
      ...invoiceRoutes,
      ...inboxRoutes,
      ...calendarRoutes,
      ...taskManagerRoutes,
      ...contactRoutes,
      ...chatRoutes,
    ],
  },
  ...sessionsRoutes,
  {
    path: "/",
    exact: true,
    loader: () => redirect("/dashboard"),
  },
  {
    path: "*",
    element: <Error404 />,
  },
]);

export default routes;
