import React from "react";
import ReactDOM from 'react-dom'
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
// import { Store } from "./app/redux/Store";
// import App from "./App";
import App from "./app/App";
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./index.scss";
import * as serviceWorker from './serviceWorker'

const container = document.getElementById("root");
/* const root = createRoot(container); */
//const root = ReactDOM.createRoot(document.getElementById("root"));
const root = createRoot(document.getElementById('root'));

global.CGST = 9;
global.SGST = 9;
global.pageItems = [];
ReactDOM.render(
  // <React.StrictMode>
    <BrowserRouter>
    {/* <CssBaseline /> */}
    {/* <Provider store={store}> */}
      <App />
    {/* </Provider> */}
    </BrowserRouter>
  // </React.StrictMode>
  ,
  document.getElementById('root')
)

serviceWorker.unregister()
/* ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>      
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
 */