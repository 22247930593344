
const initialState = {
    serviceList: []  
  }
  
  const ServiceReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SERVICE_SUCCESS": {               
        return {
          ...state,
          getServiceSuccess: true,
          getServiceList: action.payload.data,
        };
      }
      case "GET_SERVICE_FAILURE": {
        return {
          ...state,
          getServiceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE": {
        return {
          ...state,
          getServiceSuccess: false,
          getServiceFailure: false,
          getServiceList: [],
          errorMessage: false
        };
      }

      case "GET_SERVICE_DETAILS_SUCCESS": {               
        return {
          ...state,
          getServiceDetailsSuccess: true,
          getServiceDetailsList: action.payload.data,
        };
      }
      case "GET_SERVICE_DETAILS_FAILURE": {
        return {
          ...state,
          getServiceDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE_DETAILS": {
        return {
          ...state,
          getServiceDetailsSuccess: false,
          getServiceDetailsFailure: false,
          getServiceDetailsList: [],
          errorMessage: false
        };
      }
      case "CREATE_SERVICE_SUCCESS": {             
        return {
          ...state,
          createServiceSuccess: true,
          createServiceData: action.payload.data,
        };
      }
      case "CREATE_SERVICE_FAILURE": {
        return {
          ...state,
          createServiceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SERVICE": {
        return {
          ...state,
          createServiceSuccess: false,
          createServiceFailure: false,
          createServiceData: [],
          errorMessage: false
        };
      }
      case "UPDATE_SERVICE_SUCCESS": {             
        return {
          ...state,
          updateServiceSuccess: true,
          updateServiceData: action.payload.data,
        };
      }
      case "UPDATE_SERVICE_FAILURE": {
        return {
          ...state,
          updateServiceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SERVICE": {
        return {
          ...state,
          updateServiceSuccess: false,
          updateServiceFailure: false,
          updateServiceData: [],
          errorMessage: false
        };
      } 
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ServiceReducer
  
  