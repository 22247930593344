const initialState = {
    serviceProviderTypeList: []  
  }
  
  const ServiceProviderTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SERVICE_PROVIDER_TYPE_SUCCESS": {             
        return {
          ...state,
          getServiceProviderTypeSuccess: true,
          getServiceProviderTypeList: action.payload.data,
        };
      }
      case "GET_SERVICE_PROVIDER_TYPE_FAILURE": {
        return {
          ...state,
          getServiceProviderTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE_PROVIDER_TYPE": {
        return {
          ...state,
          getServiceProviderTypeSuccess: false,
          getServiceProviderTypeFailure: false,
          getServiceProviderTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SERVICE_PROVIDER_TYPE_SUCCESS": {             
        return {
          ...state,
          createServiceProviderTypeSuccess: true,
          createServiceProviderTypeData: action.payload.data,
        };
      }
      case "CREATE_SERVICE_PROVIDER_TYPE_FAILURE": {
        return {
          ...state,
          createServiceProviderTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SERVICE_PROVIDER_TYPE": {
        return {
          ...state,
          createServiceProviderTypeSuccess: false,
          createServiceProviderTypeFailure: false,
          createServiceProviderTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SERVICE_PROVIDER_TYPE_SUCCESS": {             
        return {
          ...state,
          updateServiceProviderTypeSuccess: true,
          updateServiceProviderTypeData: action.payload.data,
        };
      }
      case "UPDATE_SERVICE_PROVIDER_TYPE_FAILURE": {
        return {
          ...state,
          updateServiceProviderTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SERVICE_PROVIDER_TYPE": {
        return {
          ...state,
          updateServiceProviderTypeSuccess: false,
          updateServiceProviderTypeFailure: false,
          updateServiceProviderTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ServiceProviderTypeReducer
  
  