const initialState = {
    serviceInfoList: []  
  }
  
  const ServiceInfoReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SERVICE_INFO_SUCCESS": {             
        return {
          ...state,
          getServiceInfoSuccess: true,
          getServiceInfoList: action.payload.data,
        };
      }
      case "GET_SERVICE_INFO_FAILURE": {
        return {
          ...state,
          getServiceInfoFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE_INFO": {
        return {
          ...state,
          getServiceInfoSuccess: false,
          getServiceInfoFailure: false,
          getServiceInfoList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SERVICE_INFO_SUCCESS": {             
        return {
          ...state,
          createServiceInfoSuccess: true,
          createServiceInfoData: action.payload.data,
        };
      }
      case "CREATE_SERVICE_INFO_FAILURE": {
        return {
          ...state,
          createServiceInfoFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SERVICE_INFO": {
        return {
          ...state,
          createServiceInfoSuccess: false,
          createServiceInfoFailure: false,
          createServiceInfoData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SERVICE_INFO_SUCCESS": {             
        return {
          ...state,
          updateServiceInfoSuccess: true,
          updateServiceInfoData: action.payload.data,
        };
      }
      case "UPDATE_SERVICE_INFO_FAILURE": {
        return {
          ...state,
          updateServiceInfoFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SERVICE_INFO": {
        return {
          ...state,
          updateServiceInfoSuccess: false,
          updateServiceInfoFailure: false,
          updateServiceInfoData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ServiceInfoReducer
  
  