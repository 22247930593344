const initialState = {
    AvailableServiceProviderList: []  
  }
  
  const AvailableServiceProviderReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_AVAILABLE_SERVICE_PROVIDER_SUCCESS": {             
        return {
          ...state,
          getAvailableServiceProviderSuccess: true,
          getAvailableServiceProviderList: action.payload.data,
        };
      }
      case "GET_AVAILABLE_SERVICE_PROVIDER_FAILURE": {
        return {
          ...state,
          getAvailableServiceProviderFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_AVAILABLE_SERVICE_PROVIDER": {
        return {
          ...state,
          getAvailableServiceProviderSuccess: false,
          getAvailableServiceProviderFailure: false,
          getAvailableServiceProviderList: [],
          errorMessage: false
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default AvailableServiceProviderReducer
  
  