import { lazy } from "react";

const ServiceIncludeList = lazy(() => import("./serviceIncludeList"));

const ServiceIncludeRoutes = [
  {
    path: "/service-include",
    element: <ServiceIncludeList />,
  }
];

export default ServiceIncludeRoutes;
