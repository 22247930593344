const initialState = {
    permissionList: []  
  }
  
  const PermissionReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_PERMISSION_SUCCESS": {             
        return {
          ...state,
          getPermissionSuccess: true,
          getPermissionList: action.payload.data,
        };
      }
      case "GET_PERMISSION_FAILURE": {
        return {
          ...state,
          getPermissionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_PERMISSION": {
        return {
          ...state,
          getPermissionSuccess: false,
          getPermissionFailure: false,
          getPermissionList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_PERMISSION_SUCCESS": {             
        return {
          ...state,
          createPermissionSuccess: true,
          createPermissionData: action.payload.data,
        };
      }
      case "CREATE_PERMISSION_FAILURE": {
        return {
          ...state,
          createPermissionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_PERMISSION": {
        return {
          ...state,
          createPermissionSuccess: false,
          createPermissionFailure: false,
          createPermissionData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_PERMISSION_SUCCESS": {             
        return {
          ...state,
          updatePermissionSuccess: true,
          updatePermissionData: action.payload.data,
        };
      }
      case "UPDATE_PERMISSION_FAILURE": {
        return {
          ...state,
          updatePermissionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_PERMISSION": {
        return {
          ...state,
          updatePermissionSuccess: false,
          updatePermissionFailure: false,
          updatePermissionData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default PermissionReducer
  
  