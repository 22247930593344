import { lazy } from "react";

const ReportCustomerOrderList = lazy(() => import("./reportCustomerOrderList"));

const reportCustomerOrderRoutes = [
  {
    path: "/customer-order-report",
    element: <ReportCustomerOrderList />,
  }
];

export default reportCustomerOrderRoutes;
