import { lazy } from "react";

const UserList = lazy(() => import("./userList"));

const userRoutes = [
  {
    path: "/user",
    element: <UserList />,
  }
];

export default userRoutes;
