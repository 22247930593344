const initialState = {
    serviceIncludeList: []  
  }
  
  const ServiceIncludeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SERVICE_INCLUDE_SUCCESS": {             
        return {
          ...state,
          getServiceIncludeSuccess: true,
          getServiceIncludeList: action.payload.data,
        };
      }
      case "GET_SERVICE_INCLUDE_FAILURE": {
        return {
          ...state,
          getServiceIncludeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE_INCLUDE": {
        return {
          ...state,
          getServiceIncludeSuccess: false,
          getServiceIncludeFailure: false,
          getServiceIncludeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SERVICE_INCLUDE_SUCCESS": {             
        return {
          ...state,
          createServiceIncludeSuccess: true,
          createServiceIncludeData: action.payload.data,
        };
      }
      case "CREATE_SERVICE_INCLUDE_FAILURE": {
        return {
          ...state,
          createServiceIncludeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SERVICE_INCLUDE": {
        return {
          ...state,
          createServiceIncludeSuccess: false,
          createServiceIncludeFailure: false,
          createServiceIncludeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SERVICE_INCLUDE_SUCCESS": {             
        return {
          ...state,
          updateServiceIncludeSuccess: true,
          updateServiceIncludeData: action.payload.data,
        };
      }
      case "UPDATE_SERVICE_INCLUDE_FAILURE": {
        return {
          ...state,
          updateServiceIncludeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SERVICE_INCLUDE": {
        return {
          ...state,
          updateServiceIncludeSuccess: false,
          updateServiceIncludeFailure: false,
          updateServiceIncludeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ServiceIncludeReducer
  
  