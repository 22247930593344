
const initialState = {
    reportOrderList: []  
    }
    
    const ReportOrderReducer = function (state = initialState, action) {  
      switch (action.type) {
        case "GET_REPORT_ORDER_SUCCESS": {               
          return {
            ...state,
            getReportOrderSuccess: true,
            getReportOrderList: action.payload.data,
          };
        }
        case "GET_REPORT_ORDER_FAILURE": {
          return {
            ...state,
            getReportOrderFailure: true,
            errorMessage: action.errorMessage
          };
        }
        case "RESET_GET_REPORT_ORDER": {
          return {
            ...state,
            getReportOrderSuccess: false,
            getReportOrderFailure: false,
            getReportOrderList: [],
            errorMessage: false
          };
        }


        case "GET_REPORT_CUSTOMER_ORDER_SUCCESS": {               
          return {
            ...state,
            getReportCustomerOrderSuccess: true,
            getReportCustomerOrderList: action.payload.data,
          };
        }
        case "GET_REPORT_CUSTOMER_ORDER_FAILURE": {
          return {
            ...state,
            getReportCustomerOrderFailure: true,
            errorMessage: action.errorMessage
          };
        }
        case "RESET_GET_REPORT_CUSTOMER_ORDER": {
          return {
            ...state,
            getReportCustomerOrderSuccess: false,
            getReportCustomerOrderFailure: false,
            getReportCustomerOrderList: [],
            errorMessage: false
          };
        }

        case "CREATE_REPORT_ORDER_SUCCESS": {             
          return {
            ...state,
            createReportOrderSuccess: true,
            createReportOrderData: action.payload.data,
          };
        }
        case "CREATE_REPORT_ORDER_FAILURE": {
          return {
            ...state,
            createReportOrderFailure: true,
            errorMessage: action.errorMessage
          };
        }
        case "RESET_CREATE_REPORT_ORDER": {
          return {
            ...state,
            createReportOrderSuccess: false,
            createReportOrderFailure: false,
            createReportOrderData: [],
            errorMessage: false
          };
        }
        case "UPDATE_REPORT_ORDER_SUCCESS": {           
          return {
            ...state,
            updateReportOrderSuccess: true,
            updateReportOrderData: action.payload.data,
          };
        }
        case "UPDATE_REPORT_ORDER_FAILURE": {
          return {
            ...state,
            updateReportOrderFailure: true,
            errorMessage: action.errorMessage
          };
        }
        case "RESET_UPDATE_REPORT_ORDER": {
          return {
            ...state,
            updateReportOrderSuccess: false,
            updateReportOrderFailure: false,
            updateReportOrderData: [],
            errorMessage: false
          };
        } 
        default: {
          return {
            ...state,
          }
        }
      }
    }
    
    export default ReportOrderReducer
    
    