const initialState = {
    zoneList: []  
  }
  
  const ZoneReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_ZONE_SUCCESS": {             
        return {
          ...state,
          getZoneSuccess: true,
          getZoneList: action.payload.data,
        };
      }
      case "GET_ZONE_FAILURE": {
        return {
          ...state,
          getZoneFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ZONE": {
        return {
          ...state,
          getZoneSuccess: false,
          getZoneFailure: false,
          getZoneList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_ZONE_SUCCESS": {             
        return {
          ...state,
          createZoneSuccess: true,
          createZoneData: action.payload.data,
        };
      }
      case "CREATE_ZONE_FAILURE": {
        return {
          ...state,
          createZoneFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ZONE": {
        return {
          ...state,
          createZoneSuccess: false,
          createZoneFailure: false,
          createZoneData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_ZONE_SUCCESS": {             
        return {
          ...state,
          updateZoneSuccess: true,
          updateZoneData: action.payload.data,
        };
      }
      case "UPDATE_ZONE_FAILURE": {
        return {
          ...state,
          updateZoneFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ZONE": {
        return {
          ...state,
          updateZoneSuccess: false,
          updateZoneFailure: false,
          updateZoneData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ZoneReducer
  
  