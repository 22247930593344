import { lazy } from "react";

const ServiceProviderList = lazy(() => import("./serviceProviderList"));

const serviceProviderRoutes = [
  {
    path: "/service-provider",
    element: <ServiceProviderList />,
  }
];

export default serviceProviderRoutes;
