const initialState = {
    serviceInfoTypeList: []  
  }
  
  const ServiceInfoTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SERVICE_INFO_TYPE_SUCCESS": {             
        return {
          ...state,
          getServiceInfoTypeSuccess: true,
          getServiceInfoTypeList: action.payload.data,
        };
      }
      case "GET_SERVICE_INFO_TYPE_FAILURE": {
        return {
          ...state,
          getServiceInfoTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE_INFO_TYPE": {
        return {
          ...state,
          getServiceInfoTypeSuccess: false,
          getServiceInfoTypeFailure: false,
          getServiceInfoTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SERVICE_INFO_TYPE_SUCCESS": {             
        return {
          ...state,
          createServiceInfoTypeSuccess: true,
          createServiceInfoTypeData: action.payload.data,
        };
      }
      case "CREATE_SERVICE_INFO_TYPE_FAILURE": {
        return {
          ...state,
          createServiceInfoTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SERVICE_INFO_TYPE": {
        return {
          ...state,
          createServiceInfoTypeSuccess: false,
          createServiceInfoTypeFailure: false,
          createServiceInfoTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SERVICE_INFO_TYPE_SUCCESS": {             
        return {
          ...state,
          updateServiceInfoTypeSuccess: true,
          updateServiceInfoTypeData: action.payload.data,
        };
      }
      case "UPDATE_SERVICE_INFO_TYPE_FAILURE": {
        return {
          ...state,
          updateServiceInfoTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SERVICE_INFO_TYPE": {
        return {
          ...state,
          updateServiceInfoTypeSuccess: false,
          updateServiceInfoTypeFailure: false,
          updateServiceInfoTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ServiceInfoTypeReducer
  
  